import { Route } from '@/types/route'

export const timetableTabRoutes = [
  {
    path: ':timetableId/details/',
    component: () =>
      import('@/views/Planner/TimetableDetail/TimetableDetailsView.vue'),
    name: 'timetable-details' as const,
    meta: {
      title: 'Timetable',
      tab: 'administration',
      permissions: ['timetable.view_timetable']
    },
    children: [
      {
        path: 'tasks',
        name: 'timetable-details-tasks' as const,
        meta: {
          title: 'Timetable',
          tab: 'administration',
          permissions: ['timetable.view_timetable']
        }
      },
      {
        path: 'tasks/:taskId',
        name: 'timetable-details-task' as const,
        meta: {
          title: 'Timetable',
          tab: 'administration',
          permissions: ['timetable.view_timetable']
        }
      }
    ] satisfies Route[]
  }
] satisfies Route[]

const timetableNotTabRoutes = [
  {
    path: 'create',
    name: 'timetable-create' as const,
    component: () => import('@/views/Planner/TimetableForm/index.vue'),
    meta: {
      title: 'Timetable create',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  },
  {
    path: ':timetableId/optimizations/',
    name: 'timetable-optimizations' as const,
    component: () => import('@/views/Planner/PotentialOptimizations/index.vue'),
    meta: {
      title: 'Timetable potential optimizations',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  },
  {
    path: ':timetableId/free-slots/',
    component: () => import('@/views/Planner/FreeSlots/index.vue'),
    name: 'timetable-free-slots' as const,
    meta: {
      title: 'Timetable free slots',
      tab: 'administration',
      permissions: ['draft_activities.view_draftactivity']
    }
  },
  {
    path: ':timetableId/rooms-import/',
    name: 'timetable-rooms-import' as const,
    component: () => import('@/views/Planner/RoomsImport/index.vue'),
    meta: {
      title: 'Import rooms from file',
      tab: 'administration',
      permissions: ['draft_activities.change_draftactivity']
    }
  },
  {
    path: 'generation/:generationTaskId',
    component: () =>
      import('@/views/Planner/GenerationProcess/GenerationProcessDetails.vue'),
    name: 'generation-process' as const,
    meta: {
      title: 'Timetable generation process',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  }
] satisfies Route[]

export const timetableRoutes = [
  {
    path: 'timetables',
    component: () => import('@/views/Planner/TimetableAndBoardingRotaView.vue'),
    name: 'timetable' as const,
    meta: {
      title: 'Timetable',
      tab: 'administration',
      permissions: ['timetable.view_timetable']
    },
    children: timetableTabRoutes
  },
  {
    path: 'timetables',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'administration'
    },
    children: timetableNotTabRoutes
  }
] satisfies Route[]
