import axios from 'axios'

// eslint-disable-next-line
import Service, { prepareURL, getQuery } from '@/api/service'
import getFormDataFromJSON from '@/functions/formdata'

axios.defaults.baseURL = import.meta.env.VITE_API_URL
axios.defaults.headers['school-id'] = import.meta.env.VITE_SCHOOL_ID
axios.defaults.headers['use-old-exceptions'] = true

/*
    using '...Service('/url/:id/')' in object provides methods:
        fetch
        get
        delete
        put
        save
 */
export const Auth = {
  me() {
    return axios.get('/panel/users/me/')
  },
  remind(payload) {
    return axios.post('/users/auth/reset/', payload)
  },
  login(payload) {
    return axios.post('/users/auth/login/', payload)
  },
  validateCode(payload) {
    return axios.post('/users/auth/login/validate-code/', payload)
  },
  changePassword(payload) {
    return axios.post('/users/auth/set_password/', payload)
  },
  logout() {
    return axios.post('/users/auth/logout/')
  },
  resetPassword(payload) {
    return axios.post('/users/auth/reset/confirm/', payload)
  }
}

export const Avatar = {
  get() {
    const url = '/users/change-avatar/'
    return axios.get(url)
  },
  update(payload) {
    return axios.put(`/users/change-avatar/`, payload)
  }
}

export const Aim = {
  all() {
    return axios.get('/aims/')
  },
  calcGrade: payload => {
    return axios.post('/aims/calc-grade/', payload)
  }
}

export const EnrollmentReport = {
  fetch(params) {
    const url = '/activities/enrollments/'
    return axios.get(url, {
      responseType: 'blob',
      params
    })
  }
}

export const StudentAim = {
  getForStudent(studentId) {
    return axios.get(`/aims/for-student/${studentId}/`)
  },
  getAimsInCurrentYear() {
    return axios.get(`/aims/for-student/`)
  }
}

export const DraftMedication = {
  ...Service('/student-applications/medications/:id/'),
  ignore: urlParams => {
    const url = '/student-applications/medications/:id/ignore/'
    return axios.post(prepareURL(url, urlParams))
  }
}

export const Assignment = {
  ...Service('/assignments/:id/'),
  fetchAsignmentsList(params) {
    const url = '/assignments/'
    return axios.get(url, params)
  },
  fetchAssignmentTypes(section) {
    return axios.get('/assignments/types/', { params: section })
  }
}

export const StudentAssignmentItem = {
  ...Service('/assignments/items/:id/')
}

export const GradeLevel = {
  ...Service('/curriculum/grade-levels/:id/')
}

export const CoursePlanner = {
  ...Service('/panel/old-planner/courses/:id/'),
  toggleLatestVersion(courseId) {
    const url = `/panel/old-planner/courses/${courseId}/toggle-latest-version/`
    return axios.post(url)
  }
}

export const CourseEnrollment = {
  fetch(params) {
    const url = '/courses/enrollments/'
    return axios.get(url, { params })
  }
}

export const Course = {
  ...Service('/courses/:id/'),
  all: (payload = {}) => {
    const params = {
      page_size: 'max',
      ...payload
    }
    return axios.get('/courses/', { params })
  },
  students({ courseId, quarterId, semesterId }) {
    const params = {
      quarter: quarterId,
      semester: semesterId
    }
    return axios.get(`/courses/${courseId}/students/`, { params })
  },
  updateDescription(id, payload) {
    const url = `/courses/${id}/update-description/`
    return axios.patch(url, payload)
  }
}

export const Quarter = {
  ...Service('/periods/quarters/:id/'),
  getQuartersForStudent(studentId) {
    return axios.get('/periods/quarters/', {
      student: studentId,
      page_size: 9999
    })
  }
}

export const Semester = Service('/panel/periods/semesters/:id/')

export const SchoolYear = Service('/panel/periods/years/:id/')

export const Department = Service('/departments/:id/')

export const Room = {
  ...Service('/panel/rooms/:id/'),
  importRooms: payload => {
    const formData = getFormDataFromJSON(payload)
    const defaultSave = Service(
      '/panel/old-planner/activities/update-activities-rooms/'
    )['save']
    return defaultSave(formData, { 'content-type': 'multipart/form-data' })
  },
  roomWithOccupation(params) {
    return axios.get(`/panel/rooms/with-occupation/`, { params })
  }
}

export const IdpSectionEnrollment = {
  ...Service('/panel/old-planner/students-idp/enroll-to-section/:id/')
}

export const Country = Service('/core/countries/:id/')

export const CourseFamily = Service('/courses-meta/families/:id/')

export const CourseSubject = Service('/panel/old-planner/courses/subjects/:id/')

export const CourseIntelligence = Service('/courses-meta/intelligences/:id/')

export const CourseGroup = Service('/courses-meta/groups/:id/')

export const CourseLevel = Service('/courses-meta/levels/:id/')

export const CourseType = Service('/courses-meta/types/:id/')

export const TimetableLog = {
  ...Service('/panel/old-planner/logs/:id/')
}

export const DraftActivity = {
  ...Service('/panel/old-planner/activities/:id/'),
  count(params) {
    const url = '/panel/old-planner/activities/count/'
    return axios.get(url, { params })
  },
  hours() {
    const url = '/panel/old-planner/activities/hours/'
    return axios.get(url)
  },
  days() {
    const url = '/panel/old-planner/activities/days/'
    return axios.get(url)
  },
  roomPreferences(activityId) {
    const url = `/panel/old-planner/activities/${activityId}/rooms-preferences/`
    return axios.get(url)
  }
}

export const Section = {
  ...Service('/panel/old-planner/sections/:id/'),
  sectionToEnroll(params) {
    const url = '/panel/old-planner/sections/section-to-enroll/'
    return axios.get(url, { params })
  },
  allowedStudents(sectionId) {
    const url = `/panel/old-planner/sections/${sectionId}/allowed-students/`
    return axios.get(url)
  },
  totalDuration(params) {
    const url = `/panel/old-planner/sections/total-duration/`
    return axios.get(url, { params })
  },
  usages(sectionId) {
    const url = `/panel/old-planner/sections/${sectionId}/usages/`
    return axios.get(url)
  }
}

export const Conflicts = {
  fetch({ day, timetableId, params }) {
    return axios.get(`/panel/old-planner/conflicts/${timetableId}/${day}/`, {
      params
    })
  }
}

export const ActivityMove = {
  fetch(activityId) {
    return axios.get(
      `/panel/old-planner/potential-optimizations/activities/${activityId}/moves/`
    )
  }
}

export const Timetable = {
  ...Service('/panel/old-planner/timetable/:id/'),
  collectPotentialOptimizations(timetableId) {
    const url = `/panel/old-planner/timetable/${timetableId}/collect-potential-optimizations/`
    return axios.post(url)
  },
  stats(timetableId) {
    const url = `/panel/old-planner/timetable/${timetableId}/stats/`
    return axios.get(url)
  },
  preview(timetableId, payload) {
    const url = `/panel/old-planner/timetable/${timetableId}/preview/`
    return axios.post(url, payload)
  },
  consistency(timetableId) {
    const url = `/panel/old-planner/timetable/${timetableId}/consistency/`
    return axios.get(url)
  },
  togglePreviewForTeachers(timetableId) {
    return axios.post(`timetable/${timetableId}/toggle-preview-for-teachers/`)
  },
  freeSlots(timetableId, params) {
    const url = `/panel/old-planner/timetable/${timetableId}/free-slots/`
    return axios.get(url, { params })
  },
  busyInSlots(timetableId, params) {
    const url = `/panel/old-planner/timetable/${timetableId}/busy-in-slot/`
    return axios.post(url, params)
  },
  currentLessons() {
    const url = '/panel/old-planner/timetable/current-lessons/'
    return axios.get(url)
  },
  copyTalentEnrichment(payload) {
    const url = '/panel/old-planner/timetable/copy-talent-enrichment/'
    return axios.post(url, payload)
  },
  fetchFilterOptions(timetableId) {
    const url = `/panel/old-planner/timetable/${timetableId}/filter-options/`
    return axios.get(url)
  }
}

export const Report = {
  downloadTeachersActivities: timetableId => {
    const url = '/panel/old-planner/activities/export-teacher-activities/'
    const params = { timetable: timetableId }
    return axios.get(url, { params, responseType: 'blob' })
  },
  downloadStudentsActivities: timetableId => {
    const url = '/panel/old-planner/activities/export-student-activities/'
    const params = { timetable: timetableId }
    return axios.get(
      url,
      { params },
      {
        responseType: 'blob'
      }
    )
  },
  downloadClassesAfter16: timetableId => {
    const url = '/panel/old-planner/activities/export-activities-after-hour/'
    const params = { timetable: timetableId, hour: 8 }
    return axios.get(
      url,
      { params },
      {
        responseType: 'blob'
      }
    )
  },
  downloadAllClassesPerSlots: timetableId => {
    const url = '/panel/old-planner/activities/export-activities-per-slot/'
    const params = { timetable: timetableId }
    return axios.get(
      url,
      { params },
      {
        responseType: 'blob'
      }
    )
  },
  downloadStudentEnrollments(quarterId) {
    const url = '/panel/old-planner/sections/export-students-enrollments/'
    const params = { quarter: quarterId }
    return axios.get(
      url,
      {
        params
      },
      {
        responseType: 'blob'
      }
    )
  },
  downloadTeacherEnrollments(quarterId) {
    const url = '/panel/old-planner/sections/export-teachers-enrolments/'
    const params = { quarter: quarterId }
    return axios.get(
      url,
      {
        params
      },
      {
        responseType: 'blob'
      }
    )
  },
  downloadStudentsInSections(quarterId) {
    const url = '/panel/old-planner/sections/export-students-in-sections/'
    const params = { quarter: quarterId }
    return axios.get(
      url,
      {
        params
      },
      {
        responseType: 'blob'
      }
    )
  }
}

export const GenerationTask = {
  ...Service('/panel/old-planner/generator/tasks/:id/'),
  stopGenerator(taskId) {
    const url = `/panel/old-planner/generator/tasks/${taskId}/stop/`
    return axios.post(url)
  },
  stats(taskId) {
    const url = `/panel/old-planner/generator/tasks/${taskId}/stats/`
    return axios.get(url)
  },
  fetchCosts() {
    const url = `/panel/old-planner/generator/tasks/costs/`
    return axios.get(url)
  }
}

export const PotentialOptimization = {
  ...Service('/panel/old-planner/potential-optimizations/:id/'),
  applyOptimizations(timetableId, optimizations) {
    return axios.put(
      `/panel/old-planner/potential-optimizations/${timetableId}/apply-potential-optimizations/`,
      {
        selected_optimizations: optimizations
      }
    )
  }
}

export const CalendarEvent = {
  ...Service('/admin/core/calendar/:id/'),
  types() {
    const url = `/admin/core/calendar/types/`
    return axios.get(url)
  }
}

export const Team = {
  ...Service('/teams/:id/'),
  fetchStaffList() {
    return axios.get(`/teams/staff-list/`)
  }
}

export const Task = {
  ...Service('/tasks-management/tasks/:id/'),
  deleteTask: (urlParams, payload) => {
    const url = '/tasks-management/tasks/:id/'
    return axios.delete(prepareURL(url, urlParams), { data: payload })
  },
  availableFilters: (urlParams, payload) => {
    const url = '/tasks-management/tasks/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  toggleIsDone: (urlParams, payload, headers = {}) => {
    // refactor to use toggleIsDone backend endpoint
    const url = '/tasks-management/tasks/:id/toggle-is-done/'
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  },
  toggleSubtaskIsDone: (urlParams, payload, headers = {}) => {
    // refactor to use toggleIsDone backend endpoint
    const url = '/tasks-management/tasks/:id//toggle-subtask-is-done/'
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  }
}
export const UserTaskTitle = Service('/tasks-management/user-task-titles/:id/')

export const LogEntry = {
  forObject({ model, objectId, params = {} }) {
    const url = '/logs/'
    params.object = `${model},${objectId}`
    return axios.get(url, { params })
  }
}

export const MyTimetable = {
  fetch: (urlParams, payload) => {
    return axios.get(prepareURL('/user-timetable/', urlParams), payload)
  },
  downloadPdf(params) {
    const url = `/user-timetable/pdf/`
    return axios.get(url, {
      params,
      responseType: 'blob'
    })
  }
}
