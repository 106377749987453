<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view v-if="$route.meta.public" />
      <div v-else>
        <transition name="fade-up">
          <router-view v-if="resourcesLoaded" />
          <Loader v-else />
        </transition>
      </div>
    </a-config-provider>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, provide } from 'vue'

import { useModal } from '@/composables/useModal'
import { usePermissions } from '@/composables/usePermissions'
import VersionChecker from '@/mixins/VersionChecker.vue'
import store from '@/store'

const markerWidget = ref()

provide('markerWidget', markerWidget)

let modalVisibilityObserver

const { isLoggedIn } = usePermissions()

const { addModalVisibilityListener, removeModalVisibilityListener } = useModal()

const resetMarkerWidget = () => {
  markerWidget.value?.unload()
  markerWidget.value = undefined
}

onMounted(() => {
  modalVisibilityObserver = addModalVisibilityListener()

  if (isLoggedIn.value) store.dispatch('fetchCommonData')
})

onUnmounted(() => {
  removeModalVisibilityListener(modalVisibilityObserver)
  resetMarkerWidget()
})
</script>

<script>
import moment from 'moment'
import 'moment/locale/en-gb'
import en_GB from 'ant-design-vue/lib/locale-provider/en_GB'
import { every } from 'lodash'

import Loader from '@/components/common/Loader.vue'

moment.locale('en-gb')

export default {
  components: {
    Loader
  },
  mixins: [VersionChecker],
  data() {
    return {
      locale: en_GB
    }
  },
  computed: {
    resourcesLoaded() {
      return every([
        this.userInfo.id,
        this.$store.state.semesters.length,
        this.$store.state.years.length
      ])
    }
  },
  async created() {
    if (window.localStorage.getItem('token')) {
      await this.$store.dispatch('fetchUserInfo')
    }
  }
}
</script>
