import { intersection } from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export function getFirstDayOfSchoolWeek() {
  /*
  Return the date of Friday. For days Friday-Sunday return the Friday
  from current week and for days Monday-Thursday return the previous week's Friday.
  * */
  return moment()
    .day(moment().day() >= 5 ? 5 : -2)
    .format('YYYY-MM-DD')
}
export function getLastDayOfSchoolWeek() {
  /*
  Return the date of Thursday. For days Thursday-Sunday return the Thursday
  from next week and for days Monday-Wednesday return the current week's Thursday.
  * */
  return moment()
    .day(moment().day() >= 4 ? 11 : 4)
    .format('YYYY-MM-DD')
}

export function getDaysBetween(firstDate, endDate, dayDiff) {
  const days = []

  if (!firstDate || !endDate || !dayDiff) {
    return days
  }

  let day = moment(firstDate)
  const end = moment(endDate)

  while (day <= end) {
    days.push(day)
    day = day.clone().add(dayDiff, 'd')
  }
  return days
}

export function downloadCSVResponse(csvString, filename) {
  const blob = new Blob([csvString])
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const a = window.document.createElement('a')

    a.href = window.URL.createObjectURL(blob, {
      type: 'text/plain'
    })
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export function isInViewport(element) {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function lowerArr(arr) {
  return arr.map(item => item.toLowerCase())
}

export function commonsInArrays(arr1, arr2) {
  return intersection(lowerArr(arr1), lowerArr(arr2)).length > 0
}

export const randomUUID = () => uuidv4()
