import Vue from 'vue'
import VueRouter, {
  NavigationFailureType,
  isNavigationFailure
} from 'vue-router'

import store from '@/store/'
import { checkIsRouteWithFeatureEnabled } from '@/utils/feature-flags'

import { administrationRoutes } from './administration/administration'

Vue.use(VueRouter)

const publicRoutes = [
  {
    path: '',
    component: () => import('@/views/Layouts/MiddleBox.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/views/Login/LoginView.vue'),
        meta: {
          public: true,
          title: 'Log in'
        }
      }
    ]
  }
]

const commonRoutes = [
  {
    path: '',
    redirect: '/teacher/timetable',
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [...administrationRoutes]
  }
]

const wildcardRoutes = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/PageNotFound/PageNotFound.vue'),
    name: 'not-found'
  }
]

const privateRoutes = [
  {
    path: '',
    redirect: '/my-timetable',
    component: () => import('@/views/Layouts/SidebarContent.vue'),
    children: [...commonRoutes, ...wildcardRoutes]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [...publicRoutes, ...privateRoutes]
})

const routeGuard = async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} — Rosenberg` : 'Rosenberg'

  const publicRoute = to.meta.public

  if (publicRoute) {
    next()
    return
  }

  const { profiles } = store.state.userInfo
  const { permissions } = store.state.userInfo

  if (!publicRoute && !profiles) {
    Vue.prototype.$notification.error({
      message: 'This page is only for logged in users. Log in!'
    })
    next({ name: 'Login', query: { next: to.path } })
    return
  }

  const routePermissions = to.meta?.permissions
  if (routePermissions) {
    const hasPermission = routePermissions.some(routePermission =>
      permissions.includes(routePermission)
    )

    if (!hasPermission || !checkIsRouteWithFeatureEnabled(to)) {
      Vue.prototype.$notification.error({
        message: "You don't have permission to view this page."
      })
      next({ name: 'Login' })
      return
    }
  }

  next()
}

router.beforeEach(routeGuard)

if (import.meta.env.PROD) {
  const handleDuplicatedNavigation = error => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated))
      throw error
  }

  const originalPush = router.push
  router.push = function push(to) {
    return originalPush.call(this, to).catch(handleDuplicatedNavigation)
  }

  const originalReplace = router.replace
  router.replace = function replace(to) {
    return originalReplace.call(this, to).catch(handleDuplicatedNavigation)
  }
}

export default router
