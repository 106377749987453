import { Parser } from '@json2csv/plainjs'
import { AxiosResponse } from 'axios'

export function downloadXlsxResponse(response: AxiosResponse) {
  const successStatusCodes: number[] = [201, 200]
  if (successStatusCodes.includes(response.status)) {
    const disposition: string = response.headers['content-disposition']
    const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/
    const match = disposition.match(filenameRegex)
    if (!match) {
      return
    }
    const [, filename] = match
    const url: string = URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      })
    )
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }
}

export function downloadCsv(rows: string[][], filename: string) {
  const parser = new Parser({ header: false })
  const parsedData = parser.parse(rows)
  const csvMime = 'data:text/csv;charset=utf-8,'
  const encodedUri = encodeURI(csvMime + parsedData)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}
