import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

// import { artisansAndAdminsRoutes } from './artisans-and-admins'
import { generatorRoutes } from './generator/generator'
// import { settingsRoutes } from './settings'

export const administrationChildrenRoutes = [
  ...generatorRoutes
  // ...artisansAndAdminsRoutes,
  // ...settingsRoutes
] satisfies Route[]

export const administrationRoutes = [
  {
    path: '',
    name: 'administration' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'administration',
      title: 'Administration'
    },
    redirect: () => getFirstPossibleRoute(administrationChildrenRoutes),
    children: administrationChildrenRoutes
  }
] satisfies Route[]
