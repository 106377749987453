import { Route } from '@/types/route'

const flags = [
  'FEATURE_BOARDING_ROTA',
  'FEATURE_NEVERENDING_FEED',
  'FEATURE_BOARDING_COVERS',
  'IS_SUMMER_CAMPS_INSTANCE'
] as const

export type FeatureFlag = typeof flags[number]

const availableFeature: string[] = import.meta.env.VITE_FEATURE_FLAGS
  ? import.meta.env.VITE_FEATURE_FLAGS.split(', ')
  : []

export function isFeatureEnabled(featureName: FeatureFlag) {
  return availableFeature.includes(featureName)
}

export function isSummerCampsInstance() {
  return isFeatureEnabled('IS_SUMMER_CAMPS_INSTANCE')
}

export function checkIsRouteWithFeatureEnabled(route: Route) {
  return route.meta.featureFlag
    ? isFeatureEnabled(route.meta.featureFlag)
    : true
}

export function checkIsNotRouteWithFeatureDisabled(route: Route) {
  return route.meta.disabledWithFeatureFlag
    ? !isFeatureEnabled(route.meta.disabledWithFeatureFlag)
    : true
}
