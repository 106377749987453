// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue'
import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
// Changing the order of imports for the router is causing issues in the application.
// eslint-disable-next-line
import router from './router'
import '@/mixins'

// Ant components
import '@/styles/theme/index.less'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// Global components

// Scss
import '@/styles/global.scss'

// Plugins
import { VueQueryPlugin } from '@tanstack/vue-query'
import Antd, { FormModel } from 'ant-design-vue'
import PortalVue from 'portal-vue'
import VueCroppie from 'vue-croppie'
import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueScreen from 'vue-screen'

import ListColsHeader from '@/components/common/ListColsHeader.vue'
import FormWrapper from '@/components/common/forms/FormWrapper'
import DatePicker from '@/components/inputs/DatePicker'
import HCheckBox from '@/components/inputs/HCheckBox'
import HDatePicker from '@/components/inputs/HDatePicker'
import HInput from '@/components/inputs/HInput'
import HInputNumber from '@/components/inputs/HInputNumber'
import HRadioGroup from '@/components/inputs/HRadioGroup'
import HRangePicker from '@/components/inputs/HRangePicker'
import HSelect from '@/components/inputs/HSelect'
import HTextArea from '@/components/inputs/HTextArea'
import HTimePicker from '@/components/inputs/HTimePicker'
import VSelect from '@/components/inputs/VSelect'
import AList from '@/components/layout/AList'
import PageHeader from '@/components/layout/PageHeader'
import 'croppie/croppie.css'
import { defaultErrorMessages } from '@/constants/error-messages'
import { errorNotification } from '@/mixins/notification'

import store from './store'

FormModel.props.validateMessages.default = () => defaultErrorMessages

Vue.use(VueScreen)
Vue.use(VueCroppie)
Vue.use(Antd)
Vue.use(PortalVue)

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput)
Vue.component('ListColsHeader', ListColsHeader)
Vue.component('AList', AList)
Vue.component('DatePicker', DatePicker)
Vue.component('FormWrapper', FormWrapper)
Vue.component('PageHeader', PageHeader)
Vue.component('VSelect', VSelect)
Vue.component('HSelect', HSelect)
Vue.component('HInput', HInput)
Vue.component('HInputNumber', HInputNumber)
Vue.component('HTextArea', HTextArea)
Vue.component('HTimePicker', HTimePicker)
Vue.component('HDatePicker', HDatePicker)
Vue.component('HRangePicker', HRangePicker)
Vue.component('HCheckBox', HCheckBox)
Vue.component('HRadioGroup', HRadioGroup)

Vue.use(VueGtag, {
  config: { id: import.meta.env.VITE_GTAG_ID }
})

if (import.meta.env.VITE_HOTJAR_ID) {
  Vue.use(Hotjar, {
    id: import.meta.env.VITE_HOTJAR_ID,
    isProduction: true
  })
}

if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY)
}

Vue.prototype.$errorNotification = errorNotification

Sentry.init({
  Vue,
  enabled: import.meta.env.MODE === 'production',
  environment: import.meta.env.VITE_ENV || 'production',
  dsn: 'https://b9edc2492cdc4175aa77d37f787db35a@o1382323.ingest.sentry.io/4505487492841472',
  integrations: [new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

Vue.config.productionTip = false

Vue.use(VueQueryPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
