<template>
  <a-button
    :icon="icon"
    :type="type"
    :data-cy="dataCy"
    :loading="loading"
    :disabled="disabled"
    :block="block"
    :size="size"
    :html-type="isSubmitType ? 'submit' : 'button'"
    :class="{ 'blue-link': isBlueLink }"
    @click="onClick"
  >
    <!-- @slot Use this slot to place content inside button. -->
    <slot>
      {{ text }}
    </slot>
  </a-button>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (event: 'click', value: PointerEvent): void
}>()

type ButtonType = 'primary' | 'secondary' | 'link' | 'dashed' | 'danger'
type ButtonSize = 'small' | 'large' | 'default'
export type ButtonProps = {
  block?: boolean
  dataCy?: string
  disabled?: boolean
  icon?: string
  isSubmitType?: boolean
  isBlueLink?: boolean
  loading?: boolean
  size?: ButtonSize
  /**
   * @deprecated use default slot instead
   */
  text?: string
  type?: ButtonType
}

withDefaults(defineProps<ButtonProps>(), {
  block: false,
  disabled: false,
  isBlueLink: false,
  isSubmitType: false,
  loading: false,
  size: 'default',
  text: '',
  type: 'primary'
})

const onClick = (event: PointerEvent) => {
  emit('click', event)
}
</script>

<style scoped lang="scss">
.ant-btn :deep(> span::first-letter) {
  text-transform: capitalize;
}
</style>
